import React, { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { CtaButton } from "@yapi/yapi2.components.buttons.cta-button";

import ProfileHeader from "../../../patients/patientProfile/ProfileHeader";
import LinkTypography from "../../../../global/components/LinkTypography/LinkTypography";
import { areEqualProps } from "../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { IModalBodyViewProps } from "./types/IModalBodyViewProps";

const ModalBody: FC<IModalBodyViewProps> = ({
  patient,
  children,
  onClick,
  onCancel,
  btnColor,
  disabled,
  btnText = "Send",
  btnCancelText = "Cancel",
}): JSX.Element => {
  return (
    <Fragment>
      <ProfileHeader paddingBottom={2} patient={patient} hidePreferences />
      {children}
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box width={230} height={38} mt={3} mb={2}>
          <CtaButton
            clickHandler={onClick}
            disabled={disabled}
            ctaText={btnText}
            dataTestid="sendButton"
          />
        </Box>
        <LinkTypography
          color={btnColor}
          text={btnCancelText}
          handleClick={onCancel}
        />
      </Box>
    </Fragment>
  );
};

export const ModalBodyView = React.memo(ModalBody, areEqualProps);
