import { EFormAssignmentNotificationType } from "../../../../global/enums/EFormAssignmentNotificationType";
import { TSelectOption } from "../../../../global/types/select/TSelectOption.type";
import {
  InOfficeOptionsArgs,
  IPendingFormsOptionsArgs,
} from "./SendTo.interfaces";

export const DEVICE_OPTION: TSelectOption<EFormAssignmentNotificationType> = {
  value: EFormAssignmentNotificationType.Device,
  label: "Linked Device",
};

export const QR_CODE_OPTION: TSelectOption<EFormAssignmentNotificationType> = {
  value: EFormAssignmentNotificationType.QRCode,
  label: "QR Code",
};

export const PATIENT_TEXT_OPTION: TSelectOption<EFormAssignmentNotificationType> =
  {
    value: EFormAssignmentNotificationType.Text,
    label: "Text",
  };

export const PATIENT_EMAIL_OPTION: TSelectOption<EFormAssignmentNotificationType> =
  {
    value: EFormAssignmentNotificationType.Email,
    label: "Email",
  };

export const PATIENT_TEXT_AND_EMAIL_OPTION: TSelectOption<EFormAssignmentNotificationType> =
  {
    value: EFormAssignmentNotificationType.TextAndEmail,
    label: "Text and Email",
  };

export const DEFAULT_SEND_TO_OPTION: TSelectOption<EFormAssignmentNotificationType> =
  DEVICE_OPTION;

export const getOptions = (
  conditions: InOfficeOptionsArgs | IPendingFormsOptionsArgs
): TSelectOption<EFormAssignmentNotificationType>[] => {
  const inOfficeConditions: InOfficeOptionsArgs =
    conditions as InOfficeOptionsArgs;
  const pendingFormsConditions: IPendingFormsOptionsArgs =
    conditions as IPendingFormsOptionsArgs;

  if (inOfficeConditions.deviceCondition) {
    return [
      {
        ...DEVICE_OPTION,
        disabled: inOfficeConditions.deviceCondition?.disabled,
        hidden: inOfficeConditions.deviceCondition?.hidden,
      },
      {
        ...QR_CODE_OPTION,
        disabled: inOfficeConditions.qrCodeCondition?.disabled,
        hidden: inOfficeConditions.qrCodeCondition?.hidden,
      },
      {
        ...PATIENT_TEXT_OPTION,
        label: PATIENT_TEXT_OPTION.label,
        disabled: inOfficeConditions.textCondition?.disabled,
        hidden: inOfficeConditions.textCondition?.hidden,
        tooltip: inOfficeConditions.tooltips?.patientText,
      },
      {
        ...PATIENT_EMAIL_OPTION,
        label: PATIENT_EMAIL_OPTION.label,
        disabled: inOfficeConditions.emailCondition?.disabled,
        hidden: inOfficeConditions.emailCondition?.hidden,
        tooltip: inOfficeConditions.tooltips?.email,
      },
    ];
  } else if (pendingFormsConditions.textAndEmailCondition) {
    return [
      {
        ...PATIENT_TEXT_OPTION,
        label: "Text",
        disabled: pendingFormsConditions.textCondition?.disabled,
        hidden: pendingFormsConditions.textCondition?.hidden,
        tooltip: pendingFormsConditions.tooltips?.patientText,
      },
      {
        ...PATIENT_EMAIL_OPTION,
        label: "Email",
        disabled: pendingFormsConditions.emailCondition?.disabled,
        hidden: pendingFormsConditions.emailCondition?.hidden,
        tooltip: pendingFormsConditions.tooltips?.email,
      },
      {
        ...PATIENT_TEXT_AND_EMAIL_OPTION,
        disabled: pendingFormsConditions.textAndEmailCondition?.disabled,
        hidden: pendingFormsConditions.textAndEmailCondition?.hidden,
        tooltip: pendingFormsConditions.tooltips?.textAndEmail,
      },
    ];
  }
};
