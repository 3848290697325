import React, { useEffect, useState } from "react";
import staticImage from "../../../../../../../../assets/image.svg";
import { SImageWrapper, SImageSmallWrapper } from "./styles";
import { setImage } from "../../../../../../../../features/patients/patientProfile/imageSlice";
import { useAppDispatch } from "../../../../../../../../global/hooks/useTypedRedux.hook";
import { useGetFeatureFlag } from "../../../../../../../../global/hooks/useGetFeatureFlag";
import { EFeatureFlags } from "../../../../../../../../global/enums/EFeatureFlags";
import { useGetResponseQuery } from "../../../../../../../../global/services/settings/practiceSettings/formLibrary/formEditor/formEditorApi";
import {
  ISurveyCredentialsInfo,
  useHostTokenFormId,
} from "../../../../../../../../global/hooks/useHostTokenFormId";
import ImageContainer from "../../../../../../../../global/components/ImageContainer/ImageContainer";

export interface UploadedImageProps {
  alt: string;
  src?: string;
  responseId: string;
  formId: number;
  fileId: string;
  patientId: string;
  imageClickHandler: () => void;
}

export const UploadedImage = ({
  alt,
  src,
  responseId,
  formId,
  fileId,
  patientId,
  imageClickHandler,
}: UploadedImageProps) => {
  const [imgSource, setImgSource] = useState<string>(src);
  const [imgLoading, setImgLoading] = useState<boolean>(true);
  const { data: responseData } = useGetResponseQuery(responseId);
  const specificFileItem = responseData?.fileResponseItems.find(
    (item) => item.name === alt && item.externalId === fileId
  );
  const surveyInputs: ISurveyCredentialsInfo = useHostTokenFormId();
  const { token } = surveyInputs;
  const { externalId, componentId } = specificFileItem || {};
  const doNothing = () => {
    // Current implementation is no good so instead of defaulting to what it was, taking it out.
    // NO-OP
  };

  const { isEnabled: isRelease_20241023FeatureEnabled } = useGetFeatureFlag(
    EFeatureFlags.Release_20241023
  );
  const imageClick = isRelease_20241023FeatureEnabled
    ? imageClickHandler
    : doNothing;

  const dispatch = useAppDispatch();

  useEffect(() => {
    const getImage = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_YAPI_DATAFORMS}/file/${externalId}?sid=${componentId}`,
          {
            method: "GET",
            headers: {
              Authorization: token,
              Accept: "image/*",
            },
          }
        );

        if (!res.ok) {
          throw new Error("Error fetching the image");
        }

        const imageBlob = await res.blob();
        const imageUrl = URL.createObjectURL(imageBlob);
        setImgSource(imageUrl);
        setImgLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error getting image");
      } finally {
        setImgLoading(false);
      }
    };

    if (externalId) {
      getImage();
    }
    return () => {
      if (imgSource) {
        URL.revokeObjectURL(imgSource);
      }
    };
  }, [externalId, responseData, token]);

  useEffect(() => {
    if (imgSource) {
      dispatch(
        setImage({
          imgSrc: imgSource,
          alt,
          responseId,
          formId,
          fileId,
          patientId,
        })
      );
    }
  }, [imgSource, alt, responseId, formId, fileId, patientId, dispatch]);
  const ImageWrapper = isRelease_20241023FeatureEnabled
    ? SImageSmallWrapper
    : SImageWrapper;
  return (
    <ImageWrapper onClick={imageClick}>
      {imgLoading ? (
        <ImageContainer />
      ) : (
        <img src={imgSource || staticImage} alt={alt} />
      )}
    </ImageWrapper>
  );
};
