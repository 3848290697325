import { useHistory, useLocation } from "react-router-dom";

import {
  selectHasFormChanges,
  updateHasFormChanges,
} from "../../features/authentication/globalMessagesSlice";
import { selectIsMobile } from "../../features/layout/layoutSlice";

import {
  navigateInternallyTo,
  openTab,
  pushToRouteHistory,
  selectCurrentTab,
  selectCurrentTabIndex,
  selectTabs,
  updateCurrentTabIndex,
  updateCurrentTabRoute,
} from "../../features/navigation/navigationSlice";

import { useAppDispatch, useAppSelector } from "./useTypedRedux.hook";
import * as H from "history";
import { useEffect } from "react";
import {
  getFullPath,
  getMergedRoutes,
} from "../helperFunctions/text/routeHistoryFormatter";
import { useLeaveWithoutSavingActions } from "./useLeaveWithoutSavingActions";
import { getTitleByPath } from "../routes/getTitleByPath";
import { isInitialRoute } from "../helperFunctions/layout/isInitialRoute";

type TLocationDescriptor = H.LocationDescriptorObject<H.LocationState>;
type TRoute = TLocationDescriptor | string;

export type TAppHistory = H.History<H.LocationState> & {
  goBackOnCurrentTab: () => void;
  push: (route: TRoute, title?: string, callBack?: () => void) => void;
  openOrNavigateInternallyTo?: (route: string, title: string) => void;
};

export const executeIfExists = (func: () => void) => {
  if (func) {
    func();
  }
};

export const getFormattedRoute = (
  route: TRoute,
  currentTabRoute: string
): string => {
  const formattedRoute = getRouteString(route);
  const isSubRoute = formattedRoute.startsWith("./");

  if (isSubRoute) {
    return getMergedRoutes(currentTabRoute, formattedRoute);
  }

  if (!formattedRoute.startsWith("/")) {
    return `/${formattedRoute}`;
  }

  return formattedRoute;
};

// Code below is used to fix the issue YAP-1492
// It`s important to access the value of latestHasFormChanges through a function
// Otherwise, it`s not updated/sync on callbacks
let latestHasFormChanges = false;

export const getHasFormChanges = () => latestHasFormChanges;
export const setFormChanges = (value: boolean) => {
  latestHasFormChanges = value;
};

export const getRouteString = (route: TRoute): string => {
  if (typeof route === "string") return route;

  return getFullPath(route);
};

export const useAppHistory = (): TAppHistory => {
  const currentTab = useAppSelector(selectCurrentTab);
  const currentTabIndex = useAppSelector(selectCurrentTabIndex);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { displayLeaveWithoutSavingModal } = useLeaveWithoutSavingActions();

  const hasFormChanges = useAppSelector(selectHasFormChanges);
  useEffect(() => {
    if (hasFormChanges !== latestHasFormChanges) {
      setFormChanges(hasFormChanges);
    }
  }, [hasFormChanges]);

  const history = useHistory();
  const isMobile = useAppSelector(selectIsMobile);
  const tabs = useAppSelector(selectTabs);

  const displayLeaveModal = (callBack: () => void) => {
    dispatch(
      displayLeaveWithoutSavingModal({
        onLeave: () => callBack(),
      })
    );
  };

  const navigateInsideTab = (route: string) => {
    history.push(route);
    dispatch(navigateInternallyTo(route));
  };

  const openOrNavigateInternallyTo = (route: string, title: string) => {
    const shouldOpenNewTab = title;
    if (shouldOpenNewTab && !isInitialRoute(route)) {
      history.push(route);
      dispatch(
        openTab({
          currentRoute: getRouteString(location),
          newTabTitle: title || getTitleByPath(route),
          newTabRoute: route,
        })
      );
      return;
    }

    navigateInsideTab(route);
  };

  const selectOrRedirect = (route: TRoute, title: string) => {
    if (isMobile) {
      const mobileTitle = getTitleByPath(route.toString());
      dispatch(
        pushToRouteHistory({
          route: route as string,
          title: title || mobileTitle,
        })
      );
      return history.push(route);
    }

    const stringRoute = getFormattedRoute(route, currentTab.route);
    const openTabIndex = tabs.findIndex((tab) => {
      if (title) {
        return tab.title === title && tab.route === route;
      }

      return tab.route.startsWith(route.toString());
    });

    const isTabOpen = openTabIndex > -1;
    if (isTabOpen) {
      if (openTabIndex !== currentTabIndex) {
        dispatch(updateCurrentTabIndex(openTabIndex));
      }
      history.push(route);
      dispatch(updateCurrentTabRoute(stringRoute));
      return;
    }

    openOrNavigateInternallyTo(stringRoute, title);
  };

  const executeOrDisplayLeaveWithoutSavingModal = (
    callBack: () => void
  ): void => {
    if (getHasFormChanges()) {
      displayLeaveModal(() => {
        dispatch(updateHasFormChanges(false));
        executeIfExists(callBack);
      });
    } else {
      executeIfExists(callBack);
    }
  };

  const goBackOnCurrentTab = () => {
    if (isMobile) {
      history.goBack();
      return;
    }
    const [previousTabRoute] = currentTab.history.slice(-1);
    const performGoBack = () => {
      if (previousTabRoute) {
        navigateInsideTab(previousTabRoute.route);
      } else {
        history.goBack();
      }
    };
    executeOrDisplayLeaveWithoutSavingModal(performGoBack);
  };

  const push = (route: TRoute, title?: string, callBack?: () => void): void => {
    const pushFunction = () => {
      selectOrRedirect(route, title);
      executeIfExists(callBack);
    };

    executeOrDisplayLeaveWithoutSavingModal(pushFunction);
  };

  return {
    ...history,
    goBackOnCurrentTab,
    push,
    openOrNavigateInternallyTo,
  };
};
