import React, { FC, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box } from "@mui/material";
import debounce from "lodash/debounce";

import {
  selectInOfficeSelectedPackagesIds,
  updateInOfficePackageName,
  updateInOfficeSelectedForms,
  updateInOfficeSelectedPackages,
  updateInOfficeSendTo,
  updateIsTabInitialized,
  updateSelectedDevice,
} from "./inOfficeModalSlice";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../global/hooks/useTypedRedux.hook";
import { AppDispatch } from "../../../../../global/store";
import { areEqualProps } from "../../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { SendTo } from "../../SendTo/SendToWrapper";
import { EFormAssignmentNotificationType } from "../../../../../global/enums/EFormAssignmentNotificationType";

import { InOfficeFormsBox } from "./InOfficeFormsBox";
import { AddFormButton } from "../../common/AddFormButton";
import { SAssignFormWrapper } from "./styles";
import { IFormData } from "../../../../../global/services/settings/practiceSettings/formLibrary/FormTable.interface";
import { IFormPackage } from "../../../../settings/practiceSettings/formsLibrary/FormsLibraryTabs/Packages/types/IFormPackage";
import { CustomPackageName } from "../../common/customPackageName/CustomPackageName";

import ImageContainer from "../../../../../global/components/ImageContainer/ImageContainer";

import { IInOfficePatientSectionProps } from "./types/IInOfficePatientSectionProps";
import { useGetFormAssignmentModalDataQuery } from "../../../../../global/services/document-manager/formAssignmentModalDataApi";

const PatientSection: FC<IInOfficePatientSectionProps> = ({
  patientId,
  showCustomPackageName,
  storedPackageName,
  sendTo,
  appointmentId,
}): JSX.Element => {
  const dispatch: AppDispatch = useAppDispatch();

  const methods = useForm({
    defaultValues: {
      packageName: storedPackageName,
      sendTo,
      appointmentId,
    },
  });

  const { watch, setValue } = methods;
  const packageName: string = watch("packageName");

  const selectedPackagedIds: string[] = useAppSelector(
    selectInOfficeSelectedPackagesIds(patientId)
  );

  const { data: patientData, isFetching } = useGetFormAssignmentModalDataQuery(
    { patientId },
    {
      skip: !patientId,
    }
  );

  useEffect(() => {
    if (!sendTo && patientData?.data?.[0]?.formAssignment) {
      const lastDeviceSelection =
        patientData?.data?.[0].formAssignment?.notifications?.at(-1);
      if (lastDeviceSelection.type in EFormAssignmentNotificationType) {
        const deviceType =
          EFormAssignmentNotificationType[lastDeviceSelection.type];
        if (deviceType) {
          dispatch(
            updateInOfficeSendTo({
              patientId,
              sendTo: EFormAssignmentNotificationType[deviceType],
            })
          );
          dispatch(updateSendTo(EFormAssignmentNotificationType[deviceType]));
          dispatch(
            updateSelectedDevice(EFormAssignmentNotificationType[deviceType])
          );
          setValue("sendTo", EFormAssignmentNotificationType[deviceType]);
        } else {
          // eslint-disable-next-line no-console
          console.error("Invalid type: ", lastDeviceSelection.type);
        }
      }
    }
  }, [patientData, patientId, dispatch]);
  const delayedUpdatePackageName = useCallback(
    debounce((newPackageName: string): void => {
      dispatch(
        updateInOfficePackageName({ packageName: newPackageName, patientId })
      );
    }, 500),
    [patientId]
  );
  // set isTabInitialized flag for current familyMember before switching to another tab
  useEffect(() => {
    return (): void => {
      dispatch(updateIsTabInitialized({ patientId, isTabInitialized: true }));
    };
  }, [patientId]);

  useEffect((): void => {
    setValue("packageName", storedPackageName);
  }, [storedPackageName]);

  useEffect((): void => {
    if (methods.getValues("sendTo") !== sendTo) {
      setValue("sendTo", sendTo);
    }
  }, [sendTo]);

  useEffect((): void => {
    delayedUpdatePackageName(packageName);
  }, [packageName]);

  const handleFormSelection = (form: IFormData): void => {
    dispatch(updateInOfficeSelectedForms({ patientId, form }));
  };

  const handlePackageSelection = (formPackage: IFormPackage): void => {
    dispatch(
      updateInOfficeSelectedPackages({ package: formPackage, patientId })
    );
  };

  const updateSendTo = useCallback(
    (value: EFormAssignmentNotificationType) => {
      return updateInOfficeSendTo({ patientId, sendTo: value });
    },
    [dispatch, patientId]
  );

  if (isFetching && !patientData)
    return (
      <Box width={450}>
        <ImageContainer marginTop={"0"} />
      </Box>
    );

  return (
    <FormProvider {...methods}>
      <SAssignFormWrapper>
        <SendTo
          patientId={patientId}
          isRequiredDoB={patientData?.requiresPatientDob ?? true}
          updateSendTo={updateSendTo}
        />
        <Box>
          <AddFormButton
            handleFormSelection={handleFormSelection}
            handlePackageSelection={handlePackageSelection}
            selectedPackagedIds={selectedPackagedIds}
          />
          {showCustomPackageName ? <CustomPackageName /> : null}
          <InOfficeFormsBox patientId={patientId} />
        </Box>
      </SAssignFormWrapper>
    </FormProvider>
  );
};

export const InOfficePatientSection = React.memo(PatientSection, areEqualProps);
