import React from "react";
import { SecondaryText } from "../../../../../../../../global/components/SecondaryText/SecondaryText";
import { Box, Typography } from "@mui/material";
import { UploadedImage } from "./UploadedImage";
import Routes from "../../../../../../../../global/routes/routes";
import { IPatientUploads } from "./types/IPatientUploads";
import { IUploadFile } from "../../../../../../../../global/services/document-manager/pending-forms/PendingFormsResponse.interface";
import { useAppHistory } from "../../../../../../../../global/hooks/useAppHistory";

export const PatientUploads = ({
  uploadedFiles,
  formId,
  patientId,
}: IPatientUploads) => {
  const history = useAppHistory();

  if (!uploadedFiles.length) return null;
  const handleImageClick = (file: IUploadFile) => {
    const { name, id, responseId } = file;
    const newRoute = Routes.DocumentManager.viewImagePreview(id, responseId);
    history.push(newRoute, name);
  };

  return (
    <>
      <SecondaryText pt={2}>Patient Uploads</SecondaryText>
      <Box display="flex" gap={2} pt={1} flexWrap="wrap">
        {uploadedFiles.map((file) => (
          <Box key={file.id} display="flex" flexDirection="column" gap={1}>
            <Typography
              sx={{
                fontSize: ".95rem !important",
                maxWidth: 170,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              title={file.name}
            >
              {file.name}
            </Typography>
            <UploadedImage
              alt={file.name}
              formId={formId}
              fileId={file.id}
              responseId={file.responseId}
              patientId={patientId}
              imageClickHandler={() => handleImageClick(file)}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};
