import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IPatientFamilyMember } from "../../../../../global/domains/patients/types/IPatientFamilyMember.interface";
import { EActiveStatus } from "../../../../../global/enums/EActiveStatus";
import { EFormAssignmentNotificationType } from "../../../../../global/enums/EFormAssignmentNotificationType";
import { IFormData } from "../../../../../global/services/settings/practiceSettings/formLibrary/FormTable.interface";
import { RootState } from "../../../../../global/store";
import { TForms } from "../../pendingForms/FormAssignerModal/pendingFormsModalsSlice";
import { EFormStatus } from "../../../../../global/types/documentManager/EFormStatus";
import { ISelectedForm } from "../../../../../global/types/documentManager/ISelectedForm.interface";
import { ISelectedPackageForm } from "../../../../../global/types/documentManager/ISelectedPackageForm.interface";
import { TDevice } from "../../../../../global/types/settings/DeviceSettings/TDevice.type";
import { latestOrder } from "../../helpers/latestOrder";
import { EFormAssignButtons } from "./types/EFormAssignButtons";
import { IFormPackage } from "../../../../settings/practiceSettings/formsLibrary/FormsLibraryTabs/Packages/types/IFormPackage";

export interface IRequestAssignForms {
  appointmentId: string;
  forms: ISelectedForm[];
  packageName: string;
  pendingPatientCheckIn: boolean;
  sendTo: EFormAssignmentNotificationType;
  patientId: string;
  deviceId?: string;
  haveAssignedForms?: boolean;
  formAssignmentId?: string;
}

export interface IPatientStateById {
  packageName: string;
  isPackageNameVisible: boolean;
  sendTo: EFormAssignmentNotificationType;
  appointmentId: string;
  selectedForms: TForms;
  submittedForms: TForms;
  selectedDevice: TDevice;
  isTabInitialized: boolean;
  // next properties are exist for assigned forms
  haveAssignedForms?: boolean;
  formAssignmentId?: string;
}

interface InOfficeModalState {
  isPatientSelectorModalVisible: boolean;
  isInOfficeFormAssignerModalVisible: boolean;
  isInOfficeQRCodeModalVisble: boolean;
  isInOfficePatientAlreadyAddedModalVisible: boolean;
  whereFormAssignerModalOpened: EFormAssignButtons;
  inOfficePatientStateById: {
    [key: string]: IPatientStateById;
  };
}

const initialState: InOfficeModalState = {
  isPatientSelectorModalVisible: false,
  isInOfficeFormAssignerModalVisible: false,
  isInOfficePatientAlreadyAddedModalVisible: false,
  isInOfficeQRCodeModalVisble: false,
  whereFormAssignerModalOpened: null,
  inOfficePatientStateById: {},
};

const inOfficeModalSlice = createSlice({
  name: "inOfficeAssignerFormModal",
  initialState,
  reducers: {
    updateIsPatientSelectorModalVisible: (
      state: InOfficeModalState,
      action: PayloadAction<boolean>
    ) => {
      state.isPatientSelectorModalVisible = action.payload;
    },
    updateIsInOfficeQRCodeModalVisible: (
      state: InOfficeModalState,
      action: PayloadAction<boolean>
    ): void => {
      state.isInOfficeQRCodeModalVisble = action.payload;
    },

    updateInOfficeIsFormAssignerModalVisible: (
      state: InOfficeModalState,
      action: PayloadAction<boolean>
    ) => {
      state.isInOfficeFormAssignerModalVisible = action.payload;
    },
    updateIsInOfficePatientAlreadyAddedModalVisible: (
      state: InOfficeModalState,
      action: PayloadAction<boolean>
    ): void => {
      state.isInOfficePatientAlreadyAddedModalVisible = action.payload;
    },
    updateInOfficePackageName: (
      state: InOfficeModalState,
      action: PayloadAction<{ packageName: string; patientId: string }>
    ): void => {
      const { packageName, patientId } = action.payload;
      if (state.inOfficePatientStateById[patientId]) {
        state.inOfficePatientStateById[patientId].packageName = packageName;
      }
    },
    updateIsPackageNameVisible: (
      state: InOfficeModalState,
      action: PayloadAction<{
        isPackageNameVisible: boolean;
        patientId: string;
      }>
    ): void => {
      const { isPackageNameVisible, patientId } = action.payload;
      if (state.inOfficePatientStateById[patientId]) {
        state.inOfficePatientStateById[patientId].isPackageNameVisible =
          isPackageNameVisible;
      }
    },
    setDefaultStateForAllFamilyMembers: (
      state: InOfficeModalState,
      action: PayloadAction<string[]>
    ): void => {
      action.payload?.forEach((patientId: string): void => {
        state.inOfficePatientStateById[patientId] = {
          packageName: null,
          isPackageNameVisible: false,
          sendTo: null,
          appointmentId: null,
          selectedForms: null,
          submittedForms: null,
          selectedDevice: null,
          isTabInitialized: false,
          haveAssignedForms: false,
          formAssignmentId: null,
        };
      });
    },
    updateInOfficeSelectedForms: (
      state: InOfficeModalState,
      action: PayloadAction<{
        patientId: string;
        form: IFormData;
      }>
    ): void => {
      const form: IFormData = action.payload?.form;
      const patientId: string = action.payload?.patientId;
      const selectedForms: ISelectedForm[] =
        state.inOfficePatientStateById?.[patientId]?.selectedForms;

      let lastOrder: number = selectedForms ? latestOrder(selectedForms) : 0;

      const newSelectedForm: ISelectedForm = {
        formId: form.id,
        formName: form.name,
        formStatus: EFormStatus.Todo,
        order: ++lastOrder,
      };

      if (selectedForms?.length) {
        state.inOfficePatientStateById[patientId].selectedForms = [
          ...selectedForms,
          newSelectedForm,
        ];
      } else {
        state.inOfficePatientStateById[patientId].selectedForms = [
          newSelectedForm,
        ];
      }
    },
    setInOfficeAlreadyAssignedForms: (
      state: InOfficeModalState,
      action: PayloadAction<{ forms: ISelectedForm[]; patientId: string }>
    ): void => {
      const { forms, patientId } = action.payload;

      if (state.inOfficePatientStateById?.[patientId]) {
        state.inOfficePatientStateById[patientId].selectedForms = forms;
      }
    },
    setInOfficeAlreadySubmittedForms: (
      state: InOfficeModalState,
      action: PayloadAction<{ forms: TForms; patientId: string }>
    ): void => {
      const { forms, patientId } = action.payload;
      state.inOfficePatientStateById[patientId].submittedForms = forms;
    },
    removeInOfficeSelectedForm: (
      state: InOfficeModalState,
      action: PayloadAction<{
        patientId: string;
        formId: number;
        order: number;
      }>
    ): void => {
      const { patientId, formId, order } = action.payload;
      const selectedForms: TForms =
        state.inOfficePatientStateById[patientId]?.selectedForms;
      const formIndexThatWillBeDeleted: number = selectedForms?.findIndex(
        (form) => form.formId === formId && form.order === order
      );

      if (formIndexThatWillBeDeleted >= 0) {
        selectedForms.splice(formIndexThatWillBeDeleted, 1);
        state.inOfficePatientStateById[patientId].selectedForms =
          selectedForms.map((form: ISelectedForm, index: number) => {
            return {
              ...form,
              order: ++index,
            };
          });
      }
    },
    updateInOfficeSelectedPackages: (
      state: InOfficeModalState,
      action: PayloadAction<{
        package: IFormPackage;
        patientId: string;
      }>
    ): void => {
      const formPackage: IFormPackage = action.payload?.package;
      const patientId: string = action.payload?.patientId;
      const selectedForms: ISelectedForm[] =
        state.inOfficePatientStateById?.[patientId]?.selectedForms;

      let lastOrder: number = selectedForms ? latestOrder(selectedForms) : 0;

      const newForms: ISelectedPackageForm[] = formPackage?.forms
        ?.filter((form): boolean => form.activeStatus === EActiveStatus.Active)
        .map((form): ISelectedPackageForm => {
          const { formId, name }: { formId: number; name: string } = form;
          return {
            formId,
            formName: name,
            order: ++lastOrder,
            formStatus: EFormStatus.Todo,
            packageId: formPackage.id,
          };
        });

      if (selectedForms?.length) {
        state.inOfficePatientStateById[patientId].selectedForms = [
          ...selectedForms,
          ...newForms,
        ];
      } else {
        state.inOfficePatientStateById[patientId].selectedForms = newForms;
      }
    },
    removeInOfficeSelectedPackage: (
      state: InOfficeModalState,
      action: PayloadAction<{ patientId: string; packageId: string }>
    ): void => {
      const { patientId, packageId } = action.payload;
      const newFormList: ISelectedForm[] = state.inOfficePatientStateById[
        patientId
      ]?.selectedForms?.filter(
        (form: ISelectedPackageForm) => form.packageId !== packageId
      );
      state.inOfficePatientStateById[patientId].selectedForms = newFormList.map(
        (form: ISelectedForm, index: number) => {
          return {
            ...form,
            order: ++index,
          };
        }
      );
    },
    updateInOfficeSendTo: (
      state: InOfficeModalState,
      action: PayloadAction<{
        patientId: string;
        sendTo: EFormAssignmentNotificationType;
      }>
    ): void => {
      const { patientId, sendTo } = action.payload;
      if (state.inOfficePatientStateById[patientId]) {
        state.inOfficePatientStateById[patientId].sendTo = sendTo;
      }
    },
    updateInOfficeAppointmentId: (
      state: InOfficeModalState,
      action: PayloadAction<{
        patientId: string;
        appointmentId: string;
      }>
    ): void => {
      const { patientId, appointmentId } = action.payload;
      if (state.inOfficePatientStateById[patientId]) {
        state.inOfficePatientStateById[patientId].appointmentId = appointmentId;
      }
    },
    updateIsTabInitialized: (
      state: InOfficeModalState,
      action: PayloadAction<{
        patientId: string;
        isTabInitialized: boolean;
      }>
    ): void => {
      const { patientId, isTabInitialized } = action.payload;
      if (state.inOfficePatientStateById[patientId]) {
        state.inOfficePatientStateById[patientId].isTabInitialized =
          isTabInitialized;
      }
    },
    updateHaveAssignedForms: (
      state: InOfficeModalState,
      action: PayloadAction<{
        patientId: string;
        haveAssignedForms: boolean;
      }>
    ): void => {
      const { patientId, haveAssignedForms } = action.payload;
      if (state.inOfficePatientStateById[patientId]) {
        state.inOfficePatientStateById[patientId].haveAssignedForms =
          haveAssignedForms;
      }
    },
    updateInOfficeFormAssignmentId: (
      state: InOfficeModalState,
      action: PayloadAction<{
        patientId: string;
        formAssignmentId: string;
      }>
    ): void => {
      const { patientId, formAssignmentId } = action.payload;
      if (state.inOfficePatientStateById[patientId]) {
        state.inOfficePatientStateById[patientId].formAssignmentId =
          formAssignmentId;
      }
    },
    updateSelectedDevice: (
      state: InOfficeModalState,
      action: PayloadAction<{
        selectedDevice: TDevice;
        patientId: string;
      }>
    ): void => {
      const { selectedDevice, patientId } = action.payload;
      if (state.inOfficePatientStateById[patientId]) {
        state.inOfficePatientStateById[patientId].selectedDevice =
          selectedDevice;
      }
    },
    setInOfficeStateToDefault: () => initialState,

    updateWhereFormAssignerModalOpened: (
      state: InOfficeModalState,
      action: PayloadAction<EFormAssignButtons>
    ): void => {
      state.whereFormAssignerModalOpened = action.payload;
    },
  },
});

export const {
  updateIsPatientSelectorModalVisible,
  updateIsInOfficeQRCodeModalVisible,
  updateInOfficeIsFormAssignerModalVisible,
  updateIsInOfficePatientAlreadyAddedModalVisible,
  updateInOfficePackageName,
  updateIsPackageNameVisible,
  setDefaultStateForAllFamilyMembers,
  updateInOfficeSelectedForms,
  updateInOfficeSelectedPackages,
  updateInOfficeSendTo,
  updateInOfficeAppointmentId,
  removeInOfficeSelectedForm,
  removeInOfficeSelectedPackage,
  updateIsTabInitialized,
  setInOfficeStateToDefault,
  setInOfficeAlreadyAssignedForms,
  updateSelectedDevice,
  updateHaveAssignedForms,
  updateInOfficeFormAssignmentId,
  updateWhereFormAssignerModalOpened,
  setInOfficeAlreadySubmittedForms,
} = inOfficeModalSlice.actions;

export const selectIsPatientSelectorModalVisible = (
  state: RootState
): boolean => state.inOfficeModalState.isPatientSelectorModalVisible;

export const selectIsInOfficeQRCodeModalVisible = (state: RootState): boolean =>
  state.inOfficeModalState.isInOfficeQRCodeModalVisble;

export const selectIsInOfficeFormAssignerModalVisible = (
  state: RootState
): boolean => state.inOfficeModalState.isInOfficeFormAssignerModalVisible;

export const selectIsInOfficePatientAlreadyAddedModalVisible = (
  state: RootState
): boolean =>
  state.inOfficeModalState.isInOfficePatientAlreadyAddedModalVisible;

export const selectWhereFormAssignerModalOpened = (
  state: RootState
): EFormAssignButtons => state.inOfficeModalState.whereFormAssignerModalOpened;

export const selectInOfficePackageName =
  (patientId: string) =>
  (state: RootState): string => {
    return state.inOfficeModalState.inOfficePatientStateById?.[patientId]
      ?.packageName;
  };

export const selectInOfficeIsPackageNameVisible =
  (patientId: string) =>
  (state: RootState): boolean => {
    return state.inOfficeModalState.inOfficePatientStateById?.[patientId]
      ?.isPackageNameVisible;
  };

export const selectInOfficePatientSelectedForms =
  (patientId: string) =>
  (state: RootState): (ISelectedForm | ISelectedPackageForm)[] => {
    return state.inOfficeModalState.inOfficePatientStateById?.[patientId]
      ?.selectedForms;
  };

export const selectInOfficePatientSubmittedForms =
  (patientId: string) =>
  (state: RootState): TForms => {
    return state.inOfficeModalState.inOfficePatientStateById?.[patientId]
      ?.submittedForms;
  };

export const selectInOfficePatientSendTo =
  (patientId: string) =>
  (state: RootState): EFormAssignmentNotificationType => {
    return state.inOfficeModalState.inOfficePatientStateById?.[patientId]
      ?.sendTo;
  };

export const selectInOfficePatientAppointmentId =
  (patientId: string) =>
  (state: RootState): string => {
    return state.inOfficeModalState.inOfficePatientStateById?.[patientId]
      ?.appointmentId;
  };

export const selectInOfficeHaveAssignedForms =
  (patientId: string) =>
  (state: RootState): boolean => {
    return state.inOfficeModalState.inOfficePatientStateById?.[patientId]
      ?.haveAssignedForms;
  };

export const selectInOfficeFormAssignmentId =
  (patientId: string) =>
  (state: RootState): string => {
    return state.inOfficeModalState.inOfficePatientStateById?.[patientId]
      ?.formAssignmentId;
  };

export const selectInOfficeSelectedDevice =
  (patientId: string) =>
  (state: RootState): TDevice => {
    return state.inOfficeModalState.inOfficePatientStateById?.[patientId]
      ?.selectedDevice;
  };

export const selectInOfficeSelectedPackagesIds =
  (patientId: string) =>
  (state: RootState): string[] => {
    const selectedForms =
      state.inOfficeModalState.inOfficePatientStateById?.[patientId]
        ?.selectedForms;
    if (!selectedForms) return [];

    const callback = (form) => form.packageId;
    const packagesIds: string[] = selectedForms.filter(callback).map(callback);
    return Array.from(new Set(packagesIds));
  };

export const selectInOfficePatientStateById =
  (patientId: string) =>
  (state: RootState): IPatientStateById => {
    return state.inOfficeModalState.inOfficePatientStateById?.[patientId];
  };

export const selectInOfficePatientsStateById = (
  state: RootState
): { [key: string]: IPatientStateById } =>
  state.inOfficeModalState.inOfficePatientStateById;

export const selectIsTabInitialized =
  (patientId: string) =>
  (state: RootState): boolean => {
    return state.inOfficeModalState.inOfficePatientStateById?.[patientId]
      ?.isTabInitialized;
  };

export const selectFamilyMembersWithoutForms =
  ({ patientTabList }: { patientTabList: IPatientFamilyMember[] }) =>
  (state: RootState): IPatientFamilyMember[] => {
    return patientTabList.filter((tab) => {
      const forms: (ISelectedForm | ISelectedPackageForm)[] =
        state.inOfficeModalState.inOfficePatientStateById?.[tab.id]
          ?.selectedForms;
      if (!forms) return true;
      return forms?.length === 0;
    });
  };

export const selectFamilyMembersWithForms =
  ({ patientTabList }: { patientTabList: IPatientFamilyMember[] }) =>
  (state: RootState): IPatientFamilyMember[] => {
    return patientTabList.filter((tab) => {
      return state.inOfficeModalState.inOfficePatientStateById?.[tab.id]
        ?.selectedForms?.length;
    });
  };

export const selectIsFamilyMembersPackageNameValid =
  ({
    patientTabList,
    mainPatientId,
  }: {
    patientTabList: IPatientFamilyMember[];
    mainPatientId: string;
  }) =>
  (state: RootState): boolean => {
    const index: number = patientTabList.findIndex((tab) => {
      if (tab.id === mainPatientId) {
        return false;
      }

      const packageName: string =
        state.inOfficeModalState.inOfficePatientStateById?.[tab.id]
          ?.packageName;
      const isPackageNameVisible: boolean =
        state.inOfficeModalState.inOfficePatientStateById?.[tab.id]
          ?.isPackageNameVisible;

      return isPackageNameVisible && !packageName;
    });

    return index === -1;
  };

export const selectSubmitFormsData =
  (patientsIds: string[]) =>
  (state: RootState): any => {
    const requestData: IRequestAssignForms[] = [];
    patientsIds.forEach((patientId) => {
      const patientStateById =
        state.inOfficeModalState.inOfficePatientStateById?.[patientId];
      if (patientStateById?.selectedForms?.length) {
        const payload: IRequestAssignForms = {
          appointmentId: patientStateById?.appointmentId,
          forms: patientStateById?.selectedForms,
          packageName: patientStateById?.packageName,
          pendingPatientCheckIn: false,
          sendTo: patientStateById?.sendTo,
          patientId,
        };

        if (
          patientStateById?.selectedDevice &&
          payload.sendTo === EFormAssignmentNotificationType.Device
        ) {
          payload.deviceId = patientStateById?.selectedDevice?.id;
        }

        if (patientStateById?.haveAssignedForms) {
          payload.haveAssignedForms = patientStateById.haveAssignedForms;
        }

        if (patientStateById?.formAssignmentId) {
          payload.formAssignmentId = patientStateById.formAssignmentId;
        }

        requestData.push(payload);
      }
    });

    return requestData;
  };

export default inOfficeModalSlice.reducer;
