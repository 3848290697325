import React from "react";
import { Box, Typography } from "@mui/material";

import { areEqualProps } from "../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { FormRadioButtons } from "../common/customFormRadioButtons/CustomFormRadioButtons";
import { ISendToView } from "./SendTo.interfaces";
import { SSendToViewWrapper } from "./styles";
import { SEND_TO } from "./constants";
import { useGetFeatureFlag } from "../../../../global/hooks/useGetFeatureFlag";
import { EFeatureFlags } from "../../../../global/enums/EFeatureFlags";

const requestFormStyles = { alignItems: "center", padding: "8px 16px" };
const assignFormTypographyStyles = {
  minWidth: "3.2rem",
  paddingTop: "0.3rem",
};
const requestFormRadioButtonStyles = { flex: 1 };

export const SendToView = React.memo(
  ({
    options,
    sendTo,
    isRequestForm,
    onChange,
    renderDevicesOrNotFoundMessage,
    setValue,
  }: ISendToView) => {
    const { isEnabled: FormAssignmentQrCodeOptionEnabled } = useGetFeatureFlag(
      EFeatureFlags.FormAssignmentQrCodeOptionEnabled
    );

    if (!FormAssignmentQrCodeOptionEnabled) {
      options = options.filter((option) => option.value !== 5);
    }

    const wrapperStyles = isRequestForm ? requestFormStyles : {};
    const radioButtonStyles = isRequestForm ? requestFormRadioButtonStyles : {};
    const typographyStyles = isRequestForm ? {} : assignFormTypographyStyles;

    return (
      <SSendToViewWrapper sx={wrapperStyles} mb={2}>
        <Typography sx={typographyStyles} variant="h6">
          Send To
        </Typography>
        <Box sx={radioButtonStyles} width="100%">
          <FormRadioButtons
            savedValue={sendTo}
            onChange={onChange}
            setValue={setValue}
            row
            name={SEND_TO}
            options={options}
          />
          {renderDevicesOrNotFoundMessage?.()}
        </Box>
      </SSendToViewWrapper>
    );
  },
  areEqualProps
);
