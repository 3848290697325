import React, { FC, useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";

import {
  updateInOfficeAppointmentId,
  setDefaultStateForAllFamilyMembers,
  selectInOfficePatientsStateById,
  IPatientStateById,
  updateInOfficeIsFormAssignerModalVisible,
  updateIsPatientSelectorModalVisible,
  updateIsInOfficeQRCodeModalVisible,
} from "./inOfficeModalSlice";

import { AppDispatch } from "../../../../../global/store";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../global/hooks/useTypedRedux.hook";
import ImageContainer from "../../../../../global/components/ImageContainer/ImageContainer";
import { FamilyMembersErrorModal } from "./FamilyMembersErrorModal";
import { QRModalBodyMemoized } from "./InOfficeQRModalBody";
import { useGetInOfficeModalState } from "./useGetInOfficeModalState";
import { areEqualProps } from "../../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { IPatientFamilyMember } from "../../../../../global/domains/patients/types/IPatientFamilyMember.interface";
import { getPatientTabList } from "./getPatientTabList";
import { IModalContentProps } from "./types/IModalContentProps";
import { InOfficeModalBody } from "./InOfficeModalBody";
import { useGetFeatureFlag } from "../../../../../global/hooks/useGetFeatureFlag";
import { IQRModalData } from "./types/IQRModalProps";
import { EFeatureFlags } from "../../../../../global/enums/EFeatureFlags";

const ModalContent: FC<IModalContentProps> = ({
  isOpen,
  setVisibility,
  patientsListRefetch,
}): JSX.Element => {
  const { isEnabled: FormAssignmentQrCodeOptionEnabled } = useGetFeatureFlag(
    EFeatureFlags.FormAssignmentQrCodeOptionEnabled
  );
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState<boolean>(false);
  const [qrData, setQRData] = useState<IQRModalData | null>(null);

  const dispatch: AppDispatch = useAppDispatch();
  const {
    isLoading,
    isFamilyMemberErrorModalVisible,
    patient,
    setFamilyMemberErrorModalVisibility,
    refetchFamilyMembers,
    familyMembers,
    patientAppointments,
  } = useGetInOfficeModalState(isOpen);
  const isErrorModalShow: boolean = patient && isFamilyMemberErrorModalVisible;
  const patientState: { [key: string]: IPatientStateById } = useAppSelector(
    selectInOfficePatientsStateById
  );

  useEffect((): void => {
    const currentAppointmentId: string = patientAppointments?.[0]?.id;

    if (currentAppointmentId) {
      dispatch(
        updateInOfficeAppointmentId({
          appointmentId: currentAppointmentId,
          patientId: patient?.id,
        })
      );
    }
  }, [patientAppointments]);

  const patientTabList: IPatientFamilyMember[] = useMemo(() => {
    if (patient && familyMembers) {
      return getPatientTabList(patient, familyMembers);
    }
    return [];
  }, [patient, familyMembers]);

  useEffect((): void => {
    if (patientTabList.length) {
      const patientIds: string[] = patientTabList.map(
        (tab: IPatientFamilyMember) => tab.id
      );
      dispatch(setDefaultStateForAllFamilyMembers(patientIds));
    }
  }, [patientTabList]);
  // do not render dialog until patientState default values were set to redux
  if (isLoading || isEmpty(patientState)) return <ImageContainer />;

  if (isErrorModalShow) {
    return (
      <FamilyMembersErrorModal
        isOpen
        patientName={patient.preferredName}
        setVisibility={setFamilyMemberErrorModalVisibility}
        refetch={refetchFamilyMembers}
      />
    );
  }
  const openModalInOfficeVisibility = () => {
    dispatch(updateInOfficeIsFormAssignerModalVisible(true));
    dispatch(updateIsPatientSelectorModalVisible(true));
    setVisibility(true);
  };
  const closeQRModal = () => {
    dispatch(updateIsInOfficeQRCodeModalVisible(true));
    setIsQRCodeModalOpen(false);
  };

  return (
    <>
      {isQRCodeModalOpen && FormAssignmentQrCodeOptionEnabled ? (
        <QRModalBodyMemoized
          isOpen={isQRCodeModalOpen}
          onClose={closeQRModal}
          setVisibility={setVisibility}
          qrData={qrData}
        />
      ) : (
        <InOfficeModalBody
          patientTabList={patientTabList}
          patient={patient}
          setVisibility={setVisibility}
          patientsListRefetch={patientsListRefetch}
          onQRCodeGenerated={(data, patientName) => {
            openModalInOfficeVisibility();
            setQRData({ data, patientName });
            setIsQRCodeModalOpen(true);
          }}
        />
      )}
    </>
  );
};

export const InOfficeModalContent = React.memo(ModalContent, areEqualProps);
