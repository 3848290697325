import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { useGetPatientSummaryQuery } from "../../../../global/services/patientsApi";

import {
  updateAvailableOptionsSendTo,
  updateSelectedDevice,
} from "../common/patientSelectorModal/patientSelectorSlice";
import { selectInOfficePatientSendTo } from "../inOfficePatients/FormAssignerModal/inOfficeModalSlice";
import { areEqualProps } from "../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";
import { ISendToWrapper, IPatientInformation } from "./SendTo.interfaces";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../global/hooks/useTypedRedux.hook";
import { AppDispatch } from "../../../../global/store";
import { ENecessaryPatientFields } from "./ENecessaryPatientFields";
import { InOfficePatientsSendTo } from "./InOfficePatientsSendTo";
import { PendingFormsSendTo } from "./PendingFormsSendTo";
import { EFormAssignmentNotificationType } from "../../../../global/enums/EFormAssignmentNotificationType";
import { MOBILE } from "./constants";
import { useGetFormAssignmentModalDataQuery } from "../../../../global/services/document-manager/formAssignmentModalDataApi";

const SendToWrapper = ({
  patientId,
  isRequestForm,
  updateSendTo,
  isRequiredDoB,
}: ISendToWrapper) => {
  const dispatch: AppDispatch = useAppDispatch();
  const { watch, getValues, setValue } = useFormContext();
  const { data: patientData } = useGetFormAssignmentModalDataQuery(
    { patientId },
    {
      skip: !patientId,
    }
  );
  const { data: patient } = useGetPatientSummaryQuery(patientId);
  const sendToFromState = useAppSelector((state) =>
    selectInOfficePatientSendTo(patientId)(state)
  );
  const email: string = patient?.email;
  const dob: string = patient?.dob;
  const mobile: string = patient?.phones?.find(
    (phone) => phone?.phoneType === MOBILE
  )?.phone;
  const isDobValid = !isRequiredDoB || Boolean(dob);

  useEffect(() => {
    return () => {
      dispatch(updateAvailableOptionsSendTo([]));
    };
  }, []);

  const addMobileToMissedFields = (missedFields: string[]): void => {
    !mobile && missedFields.push(ENecessaryPatientFields.MOBILE_NUMBER);
  };

  const addDobToMissedFields = (missedFields: string[]): void => {
    isRequiredDoB && !dob && missedFields.push(ENecessaryPatientFields.DOB);
  };

  const addEmailToMissedFields = (missedFields: string[]): void => {
    !email && missedFields.push(ENecessaryPatientFields.EMAIL);
  };

  const patientTextInformation: IPatientInformation =
    useMemo((): IPatientInformation => {
      const missedFields: string[] = [];
      addMobileToMissedFields(missedFields);
      addDobToMissedFields(missedFields);

      return {
        disabled: !(isDobValid && Boolean(mobile)),
        missedFields,
      };
    }, [mobile, dob, isDobValid, isRequiredDoB]);

  const patientEmailInformation: IPatientInformation =
    useMemo((): IPatientInformation => {
      const missedFields: string[] = [];
      addEmailToMissedFields(missedFields);
      addDobToMissedFields(missedFields);

      return {
        disabled: !(isDobValid && Boolean(email)),
        missedFields,
      };
    }, [email, dob, isDobValid, isRequiredDoB]);
  const patientsSendTo = useAppSelector(selectInOfficePatientSendTo(patientId));
  const patientTextAndEmailInformation: IPatientInformation =
    useMemo((): IPatientInformation => {
      const missedFields: string[] = [];
      addEmailToMissedFields(missedFields);
      addMobileToMissedFields(missedFields);
      addDobToMissedFields(missedFields);

      return {
        disabled:
          patientTextInformation.disabled || patientEmailInformation.disabled,
        missedFields,
      };
    }, [
      patientTextInformation,
      patientEmailInformation,
      isRequiredDoB,
      email,
      mobile,
      dob,
    ]);

  const onChangeSendTo = (value: EFormAssignmentNotificationType): void => {
    if (updateSendTo) {
      dispatch(updateSendTo(value));
    }
    if (value === 3) {
      const selectedDevice = watch("selectedDevice");
      if (selectedDevice) {
        dispatch(updateSelectedDevice(selectedDevice));
      }
    }
  };

  useEffect(() => {
    if (!getValues("sendTo") && patientsSendTo) {
      setValue("sendTo", patientsSendTo);
      dispatch(updateSendTo(patientsSendTo));
    }
  }, [patientsSendTo, getValues, setValue, dispatch]);

  useEffect(() => {
    try {
      if (patientData?.data?.[0]?.formAssignment) {
        const lastDeviceSelection =
          patientData?.data?.[0].formAssignment?.notifications?.at(-1);
        if (
          !sendToFromState &&
          updateSendTo &&
          lastDeviceSelection &&
          lastDeviceSelection.type in EFormAssignmentNotificationType
        ) {
          const device =
            EFormAssignmentNotificationType[lastDeviceSelection.type];

          if (device) {
            dispatch(updateSendTo(EFormAssignmentNotificationType[device]));
            dispatch(
              updateSelectedDevice(EFormAssignmentNotificationType[device])
            );
          } else {
            // eslint-disable-next-line no-console
            console.error("Invalid type: ", lastDeviceSelection.type);
          }
        }
      } else {
        // eslint-disable-next-line no-console
        console.info("No data or formAssignment info yet.");
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("No formAssignments yet", err);
    }
  }, [patientData, sendToFromState, updateSendTo]);
  return isRequestForm ? (
    <PendingFormsSendTo
      patientTextInformation={patientTextInformation}
      patientEmailInformation={patientEmailInformation}
      patientTextAndEmailInformation={patientTextAndEmailInformation}
      dispatch={dispatch}
      onChange={onChangeSendTo}
      setValue={setValue}
    />
  ) : (
    <InOfficePatientsSendTo
      patientId={patientId}
      dispatch={dispatch}
      setValue={setValue}
      watch={watch}
      patientTextInformation={patientTextInformation}
      patientEmailInformation={patientEmailInformation}
      onChange={onChangeSendTo}
    />
  );
};

export const SendTo = React.memo(SendToWrapper, areEqualProps);
