import React, { useCallback, useEffect } from "react";
import { Box, Divider, styled, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import dayjs from "dayjs";

import { SecondaryText } from "../../../../global/components/SecondaryText/SecondaryText";
import { MoreIcon } from "../../MoreIcon";
import { PillButton } from "../../../../global/components/PillButton/PillButton";
import { PatientSelectorModal } from "../common/patientSelectorModal/PatientSelectorModal";

import {
  selectIsInOfficeFormAssignerModalVisible,
  selectIsInOfficeQRCodeModalVisible,
  selectIsPatientSelectorModalVisible,
  updateInOfficeIsFormAssignerModalVisible,
  updateIsInOfficeQRCodeModalVisible,
  updateIsInOfficePatientAlreadyAddedModalVisible,
  updateIsPatientSelectorModalVisible,
  updateWhereFormAssignerModalOpened,
} from "./FormAssignerModal/inOfficeModalSlice";

import {
  resetInOfficePatients,
  selectCheckInOutAppointmentId,
  selectInOfficePatientList,
  selectReFetchOutstandingTasksKey,
  selectScheduledAppointments,
  selectTransitionedFormAssignmentIdToPending,
  updateCheckInOutAppointmentId,
  updateInOfficePatientList,
  updateTotalCount,
  updateTransitionedFormAssignmentIdToPending,
} from "./inOfficePatientsSlice";
import {
  clearState,
  updateSelectedPatientId,
} from "../common/patientSelectorModal/patientSelectorSlice";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../global/hooks/useTypedRedux.hook";
import { InOfficePatientList } from "./inOfficePatientList/InOfficePatientList";
import {
  useGetInOfficePatientsQuery,
  useLazyGetCheckedInPatientQuery,
} from "../../../../global/services/document-manager/inOfficePatientsApi";
import { InOfficeFormsAssignerModal } from "./FormAssignerModal/InOfficeFormsAssignerModal";
import { useFormOptionsLoader } from "./hooks/useFormOptionsLoader";
import { usePackageOptionsLoader } from "./hooks/usePackageOptionsLoader";
import { updateIsLoading } from "../../documentManagerSlice";
import { selectPractice } from "../../../authentication/appSlice";
import { getPmsName } from "../../../../global/helperFunctions/text/getPmsName";
import { PatientAlreadyAddedModal } from "../common/patientSelectorModal/patientSearch/PatientAlreadyAddedModal";
import { TPatient } from "../../../../global/domains/patients/types/TPatient.type";
import { EFormAssignButtons } from "./FormAssignerModal/types/EFormAssignButtons";
import { useRefetchOnPubnubNotification } from "./hooks/useRefetchOnPubnubNotification";
import {
  FormVersionBumpedEvent,
  selectBrokenAppointmentId,
  selectDeletedAppointmentId,
  selectFormVersionBumpedData,
  selectFutureAppointmentId,
  selectReloadAppointmentsKey,
  updateBrokenAppointmentId,
  updateDeletedAppointmentId,
  updateFormVersionBumpedData,
  updateFutureAppointmentId,
  updateReloadAppointmentsKey,
} from "../pendingForms/pendingFormsSlice";

const SAssignFormButton = styled(PillButton)({
  marginTop: 16,
  marginBottom: 16,
  width: 140,
  height: "initial",
  flexGrow: "initial",
});

const SDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const SDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.text.secondary,
  height: 12,
  marginLeft: 6,
  marginRight: 6,
}));

export const inOfficePageSize = 500;
export const pageIndex = 1;

const InOfficePatients = () => {
  const pmsName = getPmsName(useAppSelector(selectPractice).pmsType);
  const TITLE_MODAL_TEXT = "Assign Form";
  const BODY_MODAL_TEXT = `Use the search bar to find a patient. If you can't find the
        patient, make sure they've been added to ${pmsName}.`;
  const StartIcon: JSX.Element = <AddCircle color="success" />;

  const dispatch = useAppDispatch();
  const searchParams = {
    pageIndex,
    pageSize: inOfficePageSize,
  };

  const {
    data: inOfficePatientsApiResponse,
    isFetching,
    refetch: inOfficePatientsRefetch,
  } = useGetInOfficePatientsQuery(searchParams, {
    refetchOnMountOrArgChange: true,
  });
  const [getCheckedInPatient] = useLazyGetCheckedInPatientQuery();

  const currentDate = dayjs().format("MMM DD, YYYY");
  const isSelectPatientModalVisible: boolean = useAppSelector(
    selectIsPatientSelectorModalVisible
  );
  const isAssignFormModalVisible: boolean = useAppSelector(
    selectIsInOfficeFormAssignerModalVisible
  );
  const inOfficePatientList = useAppSelector(selectInOfficePatientList);
  const reFetchOutstandingTasksKey = useAppSelector(
    selectReFetchOutstandingTasksKey
  );
  const scheduledAppointments = useAppSelector(selectScheduledAppointments);

  const hasPatients = Boolean(inOfficePatientList?.length);
  const isQRCodeModalOpen = useAppSelector(selectIsInOfficeQRCodeModalVisible);

  useEffect(() => {
    const hasAddedNewForm = pageIndex === 1;
    if (hasAddedNewForm) {
      // This ensures list goes to the top whenever a new Form is added
      window.document.getElementById("infinite-scroll")?.scrollTo(0, 0);
    }
  }, [pageIndex]);

  useEffect(() => {
    dispatch(updateIsLoading(isFetching));
    if (isFetching) return;
    const returnedInOfficePatientList = inOfficePatientsApiResponse?.data || [];
    const totalRows = inOfficePatientsApiResponse?.page?.totalRows || 0;

    dispatch(updateTotalCount(totalRows));
    dispatch(updateInOfficePatientList(returnedInOfficePatientList));
  }, [isFetching]);

  useFormOptionsLoader();
  usePackageOptionsLoader();

  //CheckInOut
  useRefetchOnPubnubNotification(
    inOfficePatientsRefetch,
    selectCheckInOutAppointmentId,
    updateCheckInOutAppointmentId
  );
  //AppointmentWasBroken
  useRefetchOnPubnubNotification(
    inOfficePatientsRefetch,
    selectBrokenAppointmentId,
    updateBrokenAppointmentId
  );
  //AppointmentWasDeleted
  useRefetchOnPubnubNotification(
    inOfficePatientsRefetch,
    selectDeletedAppointmentId,
    updateDeletedAppointmentId
  );
  //AppointmentChangedForFormAssignments
  useRefetchOnPubnubNotification(
    inOfficePatientsRefetch,
    selectReloadAppointmentsKey,
    updateReloadAppointmentsKey
  );
  //FormVersionBumped
  useRefetchOnPubnubNotification<FormVersionBumpedEvent[]>(
    inOfficePatientsRefetch,
    selectFormVersionBumpedData,
    updateFormVersionBumpedData
  );

  //FutureAppointmentUpdated
  useRefetchOnPubnubNotification(
    inOfficePatientsRefetch,
    selectFutureAppointmentId,
    updateFutureAppointmentId
  );

  //FormAssignmentTransitionedToPending
  useRefetchOnPubnubNotification(
    inOfficePatientsRefetch,
    selectTransitionedFormAssignmentIdToPending,
    updateTransitionedFormAssignmentIdToPending
  );

  useEffect(() => {
    dispatch(resetInOfficePatients());
  }, []);

  useEffect(() => {
    if (reFetchOutstandingTasksKey) {
      inOfficePatientsRefetch();
    }
  }, [reFetchOutstandingTasksKey]);

  useEffect(() => {
    inOfficePatientsRefetch();
  }, [scheduledAppointments?.length]);

  useEffect(() => {
    dispatch(updateIsPatientSelectorModalVisible(false));
    dispatch(updateInOfficeIsFormAssignerModalVisible(false));
    dispatch(updateIsInOfficePatientAlreadyAddedModalVisible(false));
  }, []);

  const setFormAssignerModalVisibility = (visible: boolean) =>
    dispatch(updateInOfficeIsFormAssignerModalVisible(visible));

  const setPatientSelectorModalVisibility = (visible: boolean) =>
    dispatch(updateIsPatientSelectorModalVisible(visible));

  const handleAssignForm = () => {
    dispatch(updateWhereFormAssignerModalOpened(EFormAssignButtons.AddPatient));
    dispatch(updateIsPatientSelectorModalVisible(false));
    dispatch(updateInOfficeIsFormAssignerModalVisible(true));
  };

  const clearStateHandler = (): void => {
    dispatch(clearState());
  };

  const setSelectedPatient = (id: string): void => {
    dispatch(updateSelectedPatientId(id));
  };

  const onPatientCardClickHandler = async (
    patient: TPatient
  ): Promise<void> => {
    try {
      const showErrorModal = (): void => {
        dispatch(updateIsInOfficePatientAlreadyAddedModalVisible(true));
        setSelectedPatient(null);
      };

      const { isPatientInOffice }: { isPatientInOffice: boolean } =
        await getCheckedInPatient(patient.id).unwrap();

      isPatientInOffice ? showErrorModal() : setSelectedPatient(patient.id);
    } catch (e) {
      return;
    }
  };

  const loaderListOrStatusMessage = useCallback(() => {
    return hasPatients ? (
      <InOfficePatientList inOfficePatientList={inOfficePatientList} />
    ) : (
      <SecondaryText>No patients found.</SecondaryText>
    );
  }, [hasPatients, inOfficePatientList]);

  const pillButtonClickHandler = (): void => {
    setPatientSelectorModalVisibility(true);
  };
  const setVisibility = () => {
    setFormAssignerModalVisibility(false);
    dispatch(updateIsInOfficeQRCodeModalVisible(false));
  };

  return (
    <Box display="flex" gap={0} flexDirection="column" height="100%">
      <PatientSelectorModal
        bodyText={BODY_MODAL_TEXT}
        headerText={TITLE_MODAL_TEXT}
        ctaText={TITLE_MODAL_TEXT}
        ctaButtonHandler={handleAssignForm}
        changeVisibility={setPatientSelectorModalVisibility}
        isOpen={isSelectPatientModalVisible}
        clearState={clearStateHandler}
        onPatientCardClick={onPatientCardClickHandler}
      />
      <InOfficeFormsAssignerModal
        setVisibility={setVisibility}
        isOpen={isAssignFormModalVisible || isQRCodeModalOpen}
        patientsListRefetch={inOfficePatientsRefetch}
      />
      {!isFetching && inOfficePatientsApiResponse?.data.length ? (
        <SecondaryText lineHeight={1.2}>
          Use the dropdown icon to show the status of any forms that have been
          assigned to the patient. If there&apos;s an item in the Office To Do
          column, use the <MoreIcon /> icon to start working through your To Do
          list.
        </SecondaryText>
      ) : null}

      <SAssignFormButton
        pillButtonClickHandler={pillButtonClickHandler}
        buttonText="Add Patient"
        startIcon={StartIcon}
      />

      <Box display="flex" alignItems="center" mb={2}>
        <SDate variant="h6">{currentDate}</SDate>
        <SDivider orientation="vertical" />
        <Typography variant="h6">{"Today's Patients"}</Typography>
      </Box>
      {loaderListOrStatusMessage()}
      <PatientAlreadyAddedModal />
    </Box>
  );
};

export default InOfficePatients;
