import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { CtaButton } from "@yapi/yapi2.components.buttons.cta-button";
import LinkTypography from "../../../../../global/components/LinkTypography/LinkTypography";
import QRCode from "react-qr-code";
import { IQRModalProps } from "./types/IQRModalProps";
import { areEqualProps } from "../../../../../global/helperFunctions/propsChecker/checkIsPropsChanged";

const QRModalBody: FC<IQRModalProps> = ({
  isOpen,
  onClose,
  qrData,
  setVisibility,
}) => {
  if (!isOpen) return null;
  let qrCodeData;
  const { data, patientName } = qrData;

  if (data.length > 0) {
    qrCodeData = data[0];
  }
  const handleCancel = () => {
    onClose();
  };
  const handleDone = () => {
    setVisibility(false);
  };
  return (
    <Box
      id="InOfficeQRModalBody"
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap="24px"
      sx={{
        borderRadius:
          "1px solid var(--graphical-elements-inactive-state-gray-ececec, #E5E5E5)",
      }}
    >
      <Box
        alignItems="flex-start"
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "700",
            marginBottom: "24px",
            textAlign: "left",
            fontFamily: "Roboto Condensed",
            fontStyle: "normal",
            lineHeight: "normal",
            fontSize: "28px",
          }}
        >
          QR Code for {patientName || "Patient"}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: "var(--info-text-000000, #000)",
            marginBottom: "16px",
            textAlign: "left",
            fontWeight: "400",
            lineHeight: "normal",
            fontSize: "16px",
            fontFamily: "Roboto Condensed",
          }}
        >
          Scan the QR Code using a device you&apos;d like to send forms to.
        </Typography>
      </Box>

      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        borderRadius="8px"
        width={450}
        height={400}
        sx={{
          border: "1px solid var(--text-disabled-e-5-e-5-e-5, #E5E5E5)",
          padding: "8px 16px",
        }}
      >
        <QRCode
          value={qrCodeData?.qrCodeLink || "no link found"}
          size={150}
          style={{ height: "300px", width: "300px", padding: "8px 16px" }}
          aria-label="QR Code"
        />
      </Box>

      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box width={230} height={38} mb={2}>
          <CtaButton
            clickHandler={handleDone}
            disabled={false}
            ctaText="Done"
            dataTestid="sendButton"
          />
        </Box>
        <LinkTypography
          color="#6E6E6E"
          text="Cancel"
          handleClick={handleCancel}
        />
      </Box>
    </Box>
  );
};

export const QRModalBodyMemoized = React.memo(QRModalBody, areEqualProps);
